import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import Content from "../components/Content"

const Container = styled.div``

const AboutPage = ({ data }:{data:AboutPage}) => {
  const {
    markdownRemark: { html },
  } = data
  return (
    <Layout>
      <Helmet>
        <title>Legacy of Steel - About</title>
      </Helmet>
      <Content>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Content>
    </Layout>
  )
}

interface AboutPage {
  markdownRemark:{html:string};
}


export const query = graphql`
  query AboutPageQuery {
    markdownRemark(frontmatter: { path: { eq: "/about" } }) {
      id
      html
      frontmatter {
        title
        tags
        path
      }
    }
  }
`
export default AboutPage
